// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'The Crown Estate: Public Realm',
  filename: 'environment.fnb-846769.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: false,
    textMessage: true,
    usernamePassword: true
  },

  firebaseConfig: {
    apiKey: "AIzaSyC2NsTprpro058ltfmvmrPHiKtSwOInZj8",
    authDomain: "facilities-846769.firebaseapp.com",
    databaseURL: "https://facilities-846769-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-846769",
    storageBucket: "facilities-846769.appspot.com",
    messagingSenderId: "858763055556",
    appId: "1:858763055556:web:a1e29034eaf79f0f9ed491",
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-846769.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    // httpServer: 'http://spot.local:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    // projectTitle: 'products-playpen',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },

  productConfig: {

    // firebaseAnswersRoot: '/answers-x/facilities',
    // product:  FacilityAccessibilityProduct4.INSTANCE,
    // productType: EProductType.facilities,

    firebaseAnswersRoot: '/answers-x/public-realm',
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.publicRealm,
    canCommentOnClusters: false,
    canEditClusters: true,

  },

  preview: {

    // fireworksEnabled: true,
    // reportGeneration2: true,
    clusterSupport: true,
  },



};
